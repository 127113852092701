import React from 'react'
import { I18n } from '@front/volcanion/'

import { TableCell } from '@mui/material'
import { TextField, MenuSelectorField, ModelMenuSelectorField, NumberField } from '@front/squirtle'

const Row = ({ row, rowId, isSelected, ...props }) => <>
  <TableCell size={'small'} sx={{ maxWidth: 20 }}>
    <MenuSelectorField
      name='orderlimit.limit_type'
      options={[
        { label: I18n.t('order_limit.limit_type.warn'), value: 'warn' },
        { label: I18n.t('order_limit.limit_type.block'), value: 'block' }
      ]}
      selectFirst
      allowNone={false}
    />
  </TableCell>
  <TableCell size={'small'}>
    <NumberField
      name='orderlimit.limit_amount'
      type='float'
      InputProps={{
        inputProps: { style: { textAlign: 'center' } }
      }}
      allowNull
      minValue={0}
    />
  </TableCell>
  <TableCell size={'small'} >
    <NumberField
      name='orderlimit.limit_count'
      type='number'
      InputProps={{
        inputProps: { style: { textAlign: 'center' } }
      }}
      allowNull
      minValue={0}
    />
  </TableCell>
  <TableCell size={'small'}>
    <MenuSelectorField
      name='orderlimit.period'
      options={[
        { label: I18n.t('order_limit.period.monthly'), value: 'monthly' },
        { label: I18n.t('order_limit.period.weekly'), value: 'weekly' }
      ]}
      selectFirst
      allowNone={false}
    />
  </TableCell>
  <TableCell size={'small'}>
    <ModelMenuSelectorField
      name='orderlimit.payment_type'
      model_name={'paymenttype'}
      searchMode='search'
      labelKeys={['name_translated']}
      loadOnMount
      config={{
        allowAll: true,
        initial_filter: { disabled: false },
        sort: ['display_index ASC']
      }}
      noneFirst
      selectFirst
      noneLabel={I18n.t('all')}
    />
  </TableCell>
  <TableCell size={'small'} sx={{ width: 80 }}>
    <NumberField
      name='orderlimit.priority'
      type='number'
      InputProps={{
        inputProps: { style: { textAlign: 'center' } }
      }}
      minValue={0}
      maxValue={99}
    />
  </TableCell>
</>

export default React.memo(Row)
